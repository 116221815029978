// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import {ref, computed, watch, onMounted} from '@vue/composition-api'
import store from '@/store'
import router from "@/router";

export default function useNetworkAccountApi() {
    // Use toast
    const toast = useToast()
    // Table Handlers
    const refNetworkAccountListTable = ref(null)
    const perPage = ref(localStorage.getItem('networkAccountPerPage') ?? 10)
    const totalNetworkAccounts = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 15, 20, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const isLoading = ref(true)

    const networkData = ref(null)
    const selectedNetworkAccountData = ref(null)
    const networkFetchOptions = ref(null)

    const statusOptions = [
        {label: 'Active', value: 'active'},
        {label: 'Passive', value: 'passive'},
    ]

    const tableColumns = [
        {key: 'id', sortable: true},
        {key: 'name', sortable: true},
        {key: 'username', sortable: true},
        {key: 'status', sortable: true},
        {key: 'created_at', sortable: true},
        {key: 'actions'},
    ]

    const dataMeta = computed(() => {
        const localItemsCount = refNetworkAccountListTable.value ? refNetworkAccountListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalNetworkAccounts.value,
        }
    })

    const refetchData = () => {
        localStorage.setItem('networkAccountPerPage', perPage.value)
        refNetworkAccountListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery], () => {
        refetchData()
    })

    // ------------------------------------------------
    // Get Network Account List
    // ------------------------------------------------
    const fetchNetworkAccounts = (ctx, callback) => {
        let sort = isSortDirDesc.value ? `-${sortBy.value}` : sortBy.value
        store.dispatch('networkAccountStore/fetchNetworkAccounts', {
            sort: sort,
            page: currentPage.value,
            perPage: perPage.value,
            q: searchQuery.value,
            pagination: true
        }).then(response => {
            const {data, total} = response.data
            callback(data)
            totalNetworkAccounts.value = response.data.pagination.total
        }).catch(error => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: 'Oops! Something went wrong!',
                },
            })
        })
    }

    const fetchNetworkAccount = () => {
        store.dispatch('networkAccountStore/fetchNetworkAccount',
            {id: router.currentRoute.params.networkAccount}).then(response => {
            const {data} = response.data
            fetchNetwork(data.network_id)
            selectedNetworkAccountData.value = data
            isLoading.value = false
        }).catch(error => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: 'Oops! Something went wrong!',
                },
            })
            router.push('/network/account/list');
        })
    }

    const fetchNetworks = () => {
        store.dispatch('networkStore/fetchNetworks', {}).then(response => {
            const mapFnc = response.data.data
            networkFetchOptions.value = mapFnc.map(item => ({
                value: item.id,
                label: item.name,
                fields: item.fields
            }))
        }).catch(error => {
            console.log(error)
        })
    }

    const fetchNetwork = (id) => {
        store.dispatch('networkStore/fetchNetwork', {id: id}).then(response => {
            networkData.value = response.data.data
        }).catch(error => {
            console.log(error)
        })
    }

    return {
        fetchNetworkAccounts,
        fetchNetworkAccount,
        fetchNetworks,
        fetchNetwork,
        selectedNetworkAccountData,
        networkData,
        networkFetchOptions,
        tableColumns,
        perPage,
        currentPage,
        totalNetworkAccounts,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refNetworkAccountListTable,
        isLoading,
        refetchData,
        statusOptions,
    }
}
