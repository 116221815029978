<template>
    <b-modal id="modal-primary" hide-footer modal-class="modal-primary" centered title="Network Account Detail">
        <b-card-text v-if="networkAccountDetail && !modalLoading">
            <div class="row">
                <div class="col-12">
                    <table class="mt-1 mt-xl-0 w-100">
                        <div v-for="(item, index) in networkAccountDetail.details" :key="item.id"
                             class="transaction-item">
                            <b-media no-body v-b-modal.result-detail class="cursor-default">
                                <b-media-body>
                                    <small>{{ index }}</small>
                                    <h6 class="transaction-title mb-1" style=" line-break:anywhere;">
                                        {{ item }}
                                    </h6>
                                </b-media-body>
                            </b-media>
                        </div>
                    </table>
                </div>
            </div>
        </b-card-text>
        <div v-else-if="modalLoading" class="text-center my-2">
            <b-spinner class="d-block mx-auto mb-50"></b-spinner>
            <strong>Loading...</strong>
        </div>
    </b-modal>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BFormCheckbox,
    VBModal,
    BModal,
    BButton,
    BCardText,
    BBadge,
    BAvatarGroup,
    BAvatar,
    VBTooltip,
    BMediaAside,
    BMedia,
    BMediaBody,
    BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {avatarText} from '@core/utils/filter'

export default {
    components: {
        BCard,
        BCardHeader,
        BCardBody,
        BCollapse,
        BFormCheckbox,
        BModal,
        BButton,
        BCardText,
        BBadge,
        BAvatarGroup,
        BAvatar,
        BMediaAside,
        BMedia,
        BMediaBody,
        BSpinner,
    },
    props: {
        modalLoading: {
            type: Boolean,
            default: false,
        },
        networkAccountDetail: {}
    },
    directives: {
        'b-tooltip': VBTooltip,
        'b-modal': VBModal,
        Ripple,
    },
    setup() {
        return {
            avatarText
        }
    }
}
</script>
